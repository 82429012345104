
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'reloadPage',
  setup () {
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      const path: string | any = route.query.path
      // console.log('path', path)

      if (path) {
        router.push({ path })
      } else {
        router.go(-1)
      }
    })

    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
